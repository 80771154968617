body {
    margin: 0px;
    padding: 0px;
    height: 100%;
}

#particles {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    z-index: -1;
    background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);
}

.form {
    width: 70%;
    margin: 0 auto;
    margin-top: 20px;
    box-sizing: border-box;
    text-align: center;
}

.form input[type=text], 
.form input[type=password] {
    width: 100%;
    border: 2px solid #fff;
    border-radius: 5px;
    margin-top: 5px;
    padding: 10px;
    background: transparent;
    font-size: 12px;
    color: #fff;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;
}

.form input[type=text]::placeholder,
.form input[type=password]::placeholder {
    color: #fff;
    letter-spacing: 1px;
    opacity: 1;
}

.form button {
    color: #333;
    background-color: #00ecbc;
    width: 100%;
    border: 2px solid #00ecbc;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    letter-spacing: 2px;
    margin-top: 5px;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif;
}

.form a {
    color: #fff !important;
    font-size: 12px !important;
    /* font-family: Segoe UI, SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif; */
    font-family: 'Ubuntu', sans-serif !important;
    letter-spacing: 2px !important;
    margin: 0px !important;
    padding: 0px !important;
    text-decoration: underline !important;
}
.form a:hover{
    background-color: transparent !important;
    cursor: pointer;
}

.form p {
    color: #fff;
    font-size: 12px;
    font-family: 'Ubuntu', sans-serif;
    letter-spacing: 2px;
    margin: 0px;
    margin-top: 15px;
    padding: 0px;
}

.heading {
    width: 100%;
  
    text-align: center;
    box-sizing: border-box;
}

.heading h1 {
    color: #fff;
    font-family: 'Righteous', cursive;
    letter-spacing: 2px;
    margin: 0px;
    padding: 0px;
}

.heading p {
    color: #fff;
    font-size: 14px;
    font-family: 'Ubuntu', sans-serif;
    letter-spacing: 2px;
    margin: 0px;
    padding: 0px;
}